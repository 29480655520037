import { HTMLAttributes, ReactNode } from "react";
import {
    Tooltip,
    TooltipContent,
    TooltipTrigger,
} from "@/components/ui/tooltip.tsx";
import { Apple, Bed, Dumbbell, HeartHandshake, Wine } from "lucide-react";
import { cn } from "@/lib/utils.ts";

type DomainItemProps = HTMLAttributes<HTMLDivElement> & {
    icon: ReactNode;
    label: string;
};

function DomainItem({ icon, label, ...props }: DomainItemProps) {
    return (
        <Tooltip>
            <TooltipTrigger>
                <div
                    {...props}
                    className={`flex flex-col justify-center items-center ${props.className}`}
                >
                    {icon}
                </div>
            </TooltipTrigger>
            <TooltipContent side="bottom">{label}</TooltipContent>
        </Tooltip>
    );
}

type LifestyleMedicineAssessmentTitleProps = {
    className?: string;
};

export function LifestyleMedicineAssessmentTitle({
    className,
}: LifestyleMedicineAssessmentTitleProps) {
    return (
        <div className={cn("flex flex-col items-center space-y-2", className)}>
            <h2 className="text-3xl font-thin text-center">
                Lifestyle Medicine Assessment
            </h2>
            <div className="flex flex-row justify-center gap-6">
                <DomainItem
                    icon={<HeartHandshake />}
                    label="Connectedness"
                    className="text-purple-700"
                />
                <DomainItem
                    icon={<Dumbbell />}
                    label="Movement"
                    className="text-red-700"
                />
                <DomainItem
                    icon={<Apple />}
                    label="Nutrition"
                    className="text-green-700"
                />

                <DomainItem
                    icon={<Bed />}
                    label="Recovery"
                    className="text-blue-700"
                />
                <DomainItem
                    icon={<Wine />}
                    label="Substance Use"
                    className="text-yellow-700"
                />
            </div>
        </div>
    );
}
